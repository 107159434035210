import { Box } from '@mui/material';
import React, {FunctionComponent} from 'react';
import {Link} from "gatsby";

export type ProductTileProps = {
    image: string;
    name: string;
    url: string;
};

const ProductTile: FunctionComponent<ProductTileProps> = ({
    image,
    name,
    url
}) => {

    return <Box component={Link} to={url} sx={{
        display: 'block',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'divider',
        color: 'inherit',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        }
    }}>
        <Box component={'img'} src={image} alt={name} sx={{
            width: '100%',
            height: '210px',
            objectFit: 'contain'
        }} />

        <Box sx={{
            bgcolor: 'divider',
            textAlign: 'center',
            padding: '14px 10px',
            fontSize: '14px',
            fontWeight: 600,
        }}>
            {name}
        </Box>
    </Box>;
};

export default ProductTile;
