import React,{FunctionComponent} from 'react';
import {graphql, PageProps} from 'gatsby';
import ProductLayout from '../../components/layout/ProductLayout/ProductLayout';
import {Grid, Theme, useMediaQuery} from '@mui/material';
import ProductTile from "../../components/product/ProductTile/ProductTile";

type DataType = {
    allWpProductCategory: {
        nodes: Array<{
            id: string;
            slug: string;
            name: string;
            thumbnail: string;
        }>;
    };
};

const ProductIndexPage: FunctionComponent<PageProps<DataType>> = ({
    data
}) => {
    const categories = data.allWpProductCategory.nodes;
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    return <ProductLayout title={'Proizvodi'} >
        { isMd && <Grid container spacing={2}>
            {categories.map(category =>
                <Grid key={category.id} item xs={12} md={6} lg={4}>
                    <ProductTile
                        image={category.thumbnail}
                        name={category.name}
                        url={`/proizvodi/${category.slug}`}
                    />
                </Grid>
            )}
        </Grid>}
    </ProductLayout>;
};

export const pageQuery = graphql`
query ProductCategoryIndex {
  allWpProductCategory(sort: {fields: redosljed}) {
    nodes {
      id
      slug
      name
      thumbnail
      redosljed
    }
  }
}
`;

export default ProductIndexPage;
